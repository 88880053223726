import React, { ReactNode, useEffect } from "react";
import { useCountryStore } from "@shared/country-store/country-store.ts";

interface CountryProviderProperties {
  children: ReactNode;
}

const CountryProvider: React.FC<CountryProviderProperties> = ({ children }) => {
  const setCountry = useCountryStore((state) => state.setCountry);

  useEffect(() => {
    const urlParameters = new URLSearchParams(globalThis.location.search);
    const countryFromUrl = urlParameters.get("country");

    if (countryFromUrl) {
      setCountry(countryFromUrl);
    }
  }, [setCountry]);

  return <div>{children}</div>;
};

export default CountryProvider;
