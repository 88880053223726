import React, { useMemo } from "react";
import { AuthProvider } from "@shared/auth-provider/ui";
import { useAuthStore } from "@shared/auth-store/lib/auth.store.ts";
import CountryProvider from "@shared/country-provider/ui";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { cacheExchange } from "@urql/exchange-graphcache";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { ConfigProvider } from "antd";
import { Client, fetchExchange, Provider } from "urql";

const RootComponent: React.FC = () => {
  const isDevelopment = import.meta.env.MODE === "development";
  const accessToken = useAuthStore((state) => state.accessToken);

  console.log(accessToken, "acces token");

  const graphqlClient = useMemo(
    () => makeGraphQLClient(accessToken),
    [accessToken],
  );

  return (
    <Provider value={graphqlClient}>
      <ConfigProvider
        theme={{
          components: {
            Switch: {
              handleBg: "#20201F",
              handleSize: 12,
              trackHeight: 16,
            },
            Modal: {
              contentBg: "#252525",
              borderRadius: 0,
              borderRadiusLG: 0,
              colorBgMask: "rgba(0, 0, 0, 0.5)",
            },
          },
        }}
      >
        <AuthProvider>
          <CountryProvider>
            <Outlet />
            {isDevelopment && <TanStackRouterDevtools />}
          </CountryProvider>
        </AuthProvider>
      </ConfigProvider>
    </Provider>
  );
};

const storage = makeDefaultStorage({
  idbName: "getoutfit-cache", // The name of the IndexedDB database
  maxAge: 7, // The maximum age of the persisted data in days
});

function makeGraphQLClient(accessToken: string | undefined): Client {
  return new Client({
    url: import.meta.env.VITE_GRAPHQL_API_URL,
    exchanges: [cacheExchange({ storage }), fetchExchange],
    fetchOptions: {
      headers: accessToken
        ? {
            Authorization: `${accessToken}`,
          }
        : {},
    },
    requestPolicy: "cache-and-network",
  });
}

export const Route = createRootRoute({
  component: RootComponent,
});
