/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './../routes/__root'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()
const SearchIndexLazyImport = createFileRoute('/search/')()
const SearchResultIndexLazyImport = createFileRoute('/search-result/')()
const OccaisonsIndexLazyImport = createFileRoute('/occaisons/')()
const GenerateOutfitIndexLazyImport = createFileRoute('/generate-outfit/')()
const ChatIndexLazyImport = createFileRoute('/chat/')()
const AISearchResultIndexLazyImport = createFileRoute('/AISearchResult/')()

// Create/Update Routes

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../routes/index.lazy').then((d) => d.Route))

const SearchIndexLazyRoute = SearchIndexLazyImport.update({
  id: '/search/',
  path: '/search/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/search/index.lazy').then((d) => d.Route),
)

const SearchResultIndexLazyRoute = SearchResultIndexLazyImport.update({
  id: '/search-result/',
  path: '/search-result/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/search-result/index.lazy').then((d) => d.Route),
)

const OccaisonsIndexLazyRoute = OccaisonsIndexLazyImport.update({
  id: '/occaisons/',
  path: '/occaisons/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/occaisons/index.lazy').then((d) => d.Route),
)

const GenerateOutfitIndexLazyRoute = GenerateOutfitIndexLazyImport.update({
  id: '/generate-outfit/',
  path: '/generate-outfit/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/generate-outfit/index.lazy').then((d) => d.Route),
)

const ChatIndexLazyRoute = ChatIndexLazyImport.update({
  id: '/chat/',
  path: '/chat/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./../routes/chat/index.lazy').then((d) => d.Route))

const AISearchResultIndexLazyRoute = AISearchResultIndexLazyImport.update({
  id: '/AISearchResult/',
  path: '/AISearchResult/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./../routes/AISearchResult/index.lazy').then((d) => d.Route),
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/AISearchResult/': {
      id: '/AISearchResult/'
      path: '/AISearchResult'
      fullPath: '/AISearchResult'
      preLoaderRoute: typeof AISearchResultIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/chat/': {
      id: '/chat/'
      path: '/chat'
      fullPath: '/chat'
      preLoaderRoute: typeof ChatIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/generate-outfit/': {
      id: '/generate-outfit/'
      path: '/generate-outfit'
      fullPath: '/generate-outfit'
      preLoaderRoute: typeof GenerateOutfitIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/occaisons/': {
      id: '/occaisons/'
      path: '/occaisons'
      fullPath: '/occaisons'
      preLoaderRoute: typeof OccaisonsIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/search-result/': {
      id: '/search-result/'
      path: '/search-result'
      fullPath: '/search-result'
      preLoaderRoute: typeof SearchResultIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/search/': {
      id: '/search/'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/AISearchResult': typeof AISearchResultIndexLazyRoute
  '/chat': typeof ChatIndexLazyRoute
  '/generate-outfit': typeof GenerateOutfitIndexLazyRoute
  '/occaisons': typeof OccaisonsIndexLazyRoute
  '/search-result': typeof SearchResultIndexLazyRoute
  '/search': typeof SearchIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/AISearchResult': typeof AISearchResultIndexLazyRoute
  '/chat': typeof ChatIndexLazyRoute
  '/generate-outfit': typeof GenerateOutfitIndexLazyRoute
  '/occaisons': typeof OccaisonsIndexLazyRoute
  '/search-result': typeof SearchResultIndexLazyRoute
  '/search': typeof SearchIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/AISearchResult/': typeof AISearchResultIndexLazyRoute
  '/chat/': typeof ChatIndexLazyRoute
  '/generate-outfit/': typeof GenerateOutfitIndexLazyRoute
  '/occaisons/': typeof OccaisonsIndexLazyRoute
  '/search-result/': typeof SearchResultIndexLazyRoute
  '/search/': typeof SearchIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/AISearchResult'
    | '/chat'
    | '/generate-outfit'
    | '/occaisons'
    | '/search-result'
    | '/search'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/AISearchResult'
    | '/chat'
    | '/generate-outfit'
    | '/occaisons'
    | '/search-result'
    | '/search'
  id:
    | '__root__'
    | '/'
    | '/AISearchResult/'
    | '/chat/'
    | '/generate-outfit/'
    | '/occaisons/'
    | '/search-result/'
    | '/search/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  AISearchResultIndexLazyRoute: typeof AISearchResultIndexLazyRoute
  ChatIndexLazyRoute: typeof ChatIndexLazyRoute
  GenerateOutfitIndexLazyRoute: typeof GenerateOutfitIndexLazyRoute
  OccaisonsIndexLazyRoute: typeof OccaisonsIndexLazyRoute
  SearchResultIndexLazyRoute: typeof SearchResultIndexLazyRoute
  SearchIndexLazyRoute: typeof SearchIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  AISearchResultIndexLazyRoute: AISearchResultIndexLazyRoute,
  ChatIndexLazyRoute: ChatIndexLazyRoute,
  GenerateOutfitIndexLazyRoute: GenerateOutfitIndexLazyRoute,
  OccaisonsIndexLazyRoute: OccaisonsIndexLazyRoute,
  SearchResultIndexLazyRoute: SearchResultIndexLazyRoute,
  SearchIndexLazyRoute: SearchIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/AISearchResult/",
        "/chat/",
        "/generate-outfit/",
        "/occaisons/",
        "/search-result/",
        "/search/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/AISearchResult/": {
      "filePath": "AISearchResult/index.lazy.tsx"
    },
    "/chat/": {
      "filePath": "chat/index.lazy.tsx"
    },
    "/generate-outfit/": {
      "filePath": "generate-outfit/index.lazy.tsx"
    },
    "/occaisons/": {
      "filePath": "occaisons/index.lazy.tsx"
    },
    "/search-result/": {
      "filePath": "search-result/index.lazy.tsx"
    },
    "/search/": {
      "filePath": "search/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
