import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createJSONStorage, persist } from "zustand/middleware";

interface ICountryStore {
  country: string;
  setCountry: (country: string) => void;
}

export const useCountryStore = create(
  devtools(
    persist<ICountryStore>(
      (set) => ({
        country: "sa",
        setCountry: (country: string): void => {
          set({ country });
        },
      }),
      {
        name: "country-store",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);
